import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logoBright from '../../assets/img/illustrations/brightLogo.png';
import vericastLogo from '../../assets/img/logos/vericastlogo.png';
import vericastLogoRetail from '../../assets/img/logos/vericastlogoRETAILPORTALa.png';
import viamediaLogoSandBox from '../../assets/img/logos/viaMEDIAlogoSANDBOX.png';
import viamediaLogo from '../../assets/img/logos/viaMEDIAlogo01.png';

const Logo = ({ at, className, ...rest }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const clientLogo = localStorage.getItem('clientLogo');
  const userLogo = localStorage.getItem('userLogo'); 
  const isRole26 = user?.role === 26;
  const [isLoading, setIsLoading] = useState(true);
  const [width, setWidth] = useState();
  const [originalDimensions, setOriginalDimensions] = useState();
  
  useEffect(() => {
    if (isRole26) {
       localStorage.removeItem("userLogo");
       setIsLoading(false);   
    } 
  }, [isRole26]);

  useEffect(() => {
    if (logoBright) {
      setWidth(originalDimensions?.width);
      setIsLoading(false);
    }
     else if(userLogo||clientLogo||viamediaLogo||vericastLogo||vericastLogoRetail||viamediaLogoSandBox){
      const img = new Image();
      img.src = userLogo||clientLogo||viamediaLogo||vericastLogo||vericastLogoRetail||viamediaLogoSandBox;
      img.onload = function () {

        const naturalWidth = img.naturalWidth;
        const naturalHeight = img.naturalHeight;
        const desiredHeight = 50;
        const scaleFactor = desiredHeight / naturalHeight;

        var newWidth = naturalWidth * scaleFactor;
        if (newWidth > 300) {
          setWidth(newWidth / 2);
        } else {
          setWidth(newWidth);
        }
        setIsLoading(false);
      };
    } else 
      {
        setIsLoading(false);
      }
  }, [user?.role,userLogo ,clientLogo,viamediaLogo,vericastLogo,vericastLogoRetail,viamediaLogoSandBox]);
 
  return (
    <Link
      to="/"
      className={classNames(
        'text-decoration-none',
        { 'navbar-brand text-left': at === 'navbar-vertical' },
        { 'navbar-brand text-left': at === 'navbar-top' }
      )}
      {...rest}
    >
      <div
        className={classNames(
          'd-flex flex-center font-weight-extra-bold fs-5 mb-4',
          {
            'align-items-center py-3': at === 'navbar-vertical',
            'align-items-center': at === 'navbar-top',
            'flex-center font-weight-extra-bold fs-5 mb-4': at === 'auth',
          },
          className
        )}
      >
        <img
          className="mr-2"
          src={
            isLoading === false
              ? isRole26
                ? logoBright
                : [1].includes(user?.role) && [87].includes(user?.client_id)
                ? vericastLogoRetail
                : [957, 953, 964, 963, 962, 993].includes(user?.user_id)
                ? vericastLogo
                : [1011, 1013, 1014].includes(user?.user_id)
                ? viamediaLogoSandBox
                : [1033].includes(user?.user_id)
                ? viamediaLogo
                : userLogo && userLogo!="null"
                ? userLogo
                :logoBright
              :logoBright
              }
          alt=""
          width={width ? width : 100}
        />
      </div>
    </Link>
  );
};

Logo.propTypes = {
  at: PropTypes.oneOf(['navbar-vertical', 'navbar-top', 'auth']),
  className: PropTypes.string,
};

export default Logo;
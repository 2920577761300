import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import Dashboard from "../components/dashboard/Dashboard";
import DashboardAlt from "../components/dashboard-alt/DashboardAlt";
import NavbarTop from "../components/navbar/NavbarTop";
import NavbarVertical from "../components/navbar/NavbarVertical";
import Footer from "../components/footer/Footer";
import loadable from "@loadable/component";
import AppContext from "../context/Context";
import ProductProvider from "../components/e-commerce/ProductProvider";
import SidePanelModal from "../components/side-panel/SidePanelModal";

const DashboardRoutes = loadable(() => import("./DashboardRoutes"));

const DashboardLayout = ({ location }) => {
  const { isFluid, isTopNav } = useContext(AppContext);

  const user = JSON.parse(localStorage.getItem("user"));
  const advertiser_id = localStorage.getItem("advertiser_id"); //for hiding dashboard screen for pepsi advertiser

  useEffect(() => {
    DashboardRoutes.preload();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={isFluid ? "container-fluid" : "container"}>
      {!isTopNav && <NavbarVertical />}
      <ProductProvider>
        <div className="content">
          <NavbarTop />
          <Switch>
            <Route path="/" exact>
              {(user?.role === 24 && advertiser_id === "56")||(user?.role === 27) ? (
                <Redirect to="/components/campaignReport" />
              ) : (
                <Route path="/" exact component={Dashboard} />
              )}
            </Route>
            <Route path="/dashboard-alt" exact component={DashboardAlt} />
            <DashboardRoutes />
          </Switch>
          <Footer />
        </div>
        <SidePanelModal />
      </ProductProvider>
    </div>
  );
};

DashboardLayout.propTypes = { location: PropTypes.object.isRequired };

export default DashboardLayout;

const axios = require('axios');
const qs = require('querystring');
var user = JSON.parse(localStorage.getItem('user'));

function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': true,
    crossorigin: true,
  },
};

function login(email, password) {
  localStorage.removeItem('user');
  const requestBody = {
    email: email,
    password: password,
  };
 // console.log("req=", requestBody);
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/login', qs.stringify(requestBody), config)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function forgot(values) {
  const requestBody = {
    email: values.email,
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/forgotPassword', qs.stringify(requestBody), config)
    .then((response) => {
     // console.log('user response', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function activeData() {
  
  const user = JSON.parse(localStorage.getItem('user'));
  let requestParams = {};
  requestParams.role_id = user.role;
  if (user.role != 26) {
    requestParams.client_id = user.client_id
  }
  if (user.role == 10) {
    requestParams.organization_id = user.organization_id;
  }
  if (user.role == 12) {
    requestParams.location_id = user.location_id;
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
    params: requestParams
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/device/activeCount', customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function allCount() {
  const user = JSON.parse(localStorage.getItem('user'));
  let requestParams = {};
  requestParams.role_id = user.role;
  if (user.role != 26) {
    requestParams.client_id = user.client_id
  }
  if (user.role == 10) {
    requestParams.organization_id = user.organization_id;
  }
  if (user.role == 12) {
    requestParams.location_id = user.location_id;
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
    params: requestParams
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/getAllCount', customConfig)
    .then((response) => {
      if(response.data.message == 'invalid token') {
        localStorage.removeItem('user');
        window.location.href = "/";
        return false;
       }
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function clientListAll() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {};
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/clientListAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function clientAll() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {};
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/clientAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}

function clientAdd(name, theme_color, bundle_id, revenue_share, admin_name, admin_email, password, logo) {
  const requestBody = {
    client_name: name,
    color_theme: theme_color,
    bundle_id: bundle_id,
    client_perpetual_profit: revenue_share,
    admin_name: admin_name,
    email: admin_email,
    password: password,
  };
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
 // console.log("req=", requestBody);
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/client/add', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      let client_id = response.client_id;
      let type=1;
      if (logo) {
        imageUploadHandler(logo, client_id,type);
      }
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function clientEdit(clientId) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: clientId,
  };
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/editClient', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function clientUpdate(userAddFields) {

  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);

  const requestBody = {
    client_id: userAddFields.client_id,
    client_name: userAddFields.name,
    client_perpetual_profit: userAddFields.revenue_share,
    color_theme: userAddFields.theme_color,
    admin_name: userAddFields.admin_name,
    email: userAddFields.admin_email,
    bundle_id: userAddFields.bundle_id,
  };
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/updateClient', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function clientDelete(clientId) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: clientId,
  };
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/deleteClient', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function demandMap() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {};
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/getDemandMap', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function imageUploadHandler(file, client_id, type) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('id', client_id);
  formData.append('type', type);
  axios.post(process.env.REACT_APP_API_SERVER_HOST + '/v1/UploadImage', formData, customConfig)
    .then((response) => {
     // console.log(response);
    })
    .catch((error) => {
     // console.log(error);
    });
}
function locationListAll() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {};
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationListAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function locationAll() {
  // const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {};
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function userNameAll() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {};
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/userNameAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function clientChange(clientId) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: clientId,
  };
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/clientChange', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function locationListOrganizationAll(clientId, organization_id) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: clientId,
    organization_id: organization_id,
  };
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationListOrganizationAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function locationFilter(clientId, organization_id, location_id) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: clientId,
    organization_id: organization_id,
    location_id: location_id,
  };
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationFilter', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function isEmailIdExist() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
  };
  //// console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/allEmail', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function locationType() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
  };
  //// console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationType', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function playlistListAll() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
  };
  //// console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/playlistListAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function offlineVideos() {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
  };
  //// console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/offlineVideos', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function playlistListOrganizationAll(clientId, organization_id) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: clientId,
    organization_id: organization_id,
  };
  //// console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/playlistListOrganizationAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function locationAdd(userAddFields) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: userAddFields.client_id,
    name: userAddFields.name,
    address_line_1: userAddFields.address1,
    address_line_2: userAddFields.address2,
    username: userAddFields.username,
    location_type: userAddFields.location_type,
    zip: userAddFields.zip,
    country: userAddFields.country,
    state: userAddFields.state,
    city: userAddFields.city,
    phone: userAddFields.phone,
    email: userAddFields.email,
    status: userAddFields.status,
    revenue_share: userAddFields.revenue_share,
    latitude: userAddFields.latitude,
    longitude: userAddFields.longitude,
    playlist: userAddFields.playlist,
    interval: userAddFields.interval,
    duration: userAddFields.duration,
    ad_url: userAddFields.ad_url,
    offline_url: userAddFields.offline_url,
  };
 // console.log("location add=", requestBody)

  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationAdd', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function locationEdit(location_id) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    location_id: location_id,
  };
  //// console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationEdit', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function locationUpdate(userAddFields) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: userAddFields.client_id,
    name: userAddFields.name,
    address_line_1: userAddFields.address1,
    address_line_2: userAddFields.address2,
    username: userAddFields.username,
    location_type: userAddFields.location_type,
    zip: userAddFields.zip,
    country: userAddFields.country,
    state: userAddFields.state,
    city: userAddFields.city,
    phone: userAddFields.phone,
    email: userAddFields.email,
    status: userAddFields.status,
    revenue_share: userAddFields.revenue_share,
    latitude: userAddFields.latitude,
    longitude: userAddFields.longitude,
    playlist: userAddFields.playlist,
    interval: userAddFields.interval,
    duration: userAddFields.duration,
    ad_url: userAddFields.ad_url,
    offline_url: userAddFields.offline_url,
    location_id: userAddFields.location_id,
  };
 // console.log("location update=", requestBody)

  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/locationUpdate', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function usernameListClientAll(client_id) {
  const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {
    client_id: client_id,
  };
  //// console.log("req=",requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/usernameListClientAll', qs.stringify(requestBody), customConfig)
    .then((response) => {
      //// console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}
function fetchPlaylistData() {
  // const user = JSON.parse(localStorage.getItem('user'));
 // console.log("user=", user);
  const requestBody = {};
 // console.log("req=", requestBody);
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
  };
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/fetch-playlist-data', qs.stringify(requestBody), customConfig)
    .then((response) => {
     // console.log('user playlist response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}

function clientDataList() {
  var role_id=localStorage.getItem('role_id');

  var customConfig ={};
  const user = JSON.parse(localStorage.getItem('user'));
  customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    params: {
      role_id: role_id
      
    }
    };


    if(role_id == 1){

      var client_id=localStorage.getItem('client_id');

       customConfig = {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'access-token': user.token,
        },
        params: {
          role_id: role_id,
          client_id : client_id
          
        }
        };

    }

    if(role_id == 24){

      var advertiser_id=localStorage.getItem('advertiser_id');

       customConfig = {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'access-token': user.token,
        },
        params: {
          role_id: role_id,
          advertiser_id : advertiser_id
          
        }
        };

    }
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/client/listWithDetails', customConfig)
    .then((response) => {
      console.log('user response campaign filter=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function getSuper(from,to,loc,mon,client_id,org_id,loc_id,dev_id,checked,checked2,checked3) {
  var from=from;
  var to=to;

  var loca='';
  var month='';
  var clientid='';
  var orgid='';
  var locationid='';
  var deviceid='';
  var role='';
  // var client_check=1;
  var organization_check='';
  var location_check='';
  var device_check='';
  var client_check ='&client_check=1';
   var roleid=localStorage.getItem('role_id');

   if(roleid == 1 || roleid == 10){
     checked=true;
   }else if(roleid == 12){
     checked=true;
     checked2=true;
   }

   

   if(checked == true){
    organization_check='&organization_check=1';
   }

   if(checked2 == true){
    location_check='&location_check=1';
   }

   if(checked3 == true){
    device_check='&device_check=1';
   }

   if(roleid !=''){
     role='&role_id='+roleid;
   }

 if(loc !=''){
    loca='&location='+loc;
 }
 if(mon !=''){
    month='&monthly=1';
 }
 if(client_id!= ''){
    clientid='&client_id='+client_id+'';
 }
 if(org_id!= ''){
    orgid='&org_id='+org_id+'';
 }
 if(loc_id!= ''){
    locationid='&loc_id='+loc_id+'';
 }
 if(dev_id!= ''){
    deviceid='&dev_id='+dev_id+'';
 }

  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/report/all?from='+from+'&to='+to+''+month+clientid+orgid+locationid+deviceid+role+organization_check+location_check+device_check+client_check, customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function advertiserData() {
  var role_id=localStorage.getItem('role_id');

  var customConfig ={};
  const user = JSON.parse(localStorage.getItem('user'));
  customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    params: {
      role_id: role_id
      
    }
    };


    if(role_id == 1){

      var client_id=localStorage.getItem('client_id');

       customConfig = {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'access-token': user.token,
        },
        params: {
          role_id: role_id,
          client_id : client_id
          
        }
        };

    }

    if(role_id == 24){

      var advertiser_id=localStorage.getItem('advertiser_id');

      var date = new Date();
      var from = date.getFullYear() + '-' +((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) +'-01' ;
      var to = date.getFullYear() + '-' +((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()));
      
       customConfig = {
        headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'access-token': user.token,
        },
       params: {
          role_id: role_id,
          advertiser_id : advertiser_id,
          from: from,
          to: to         
        }
        };

    }
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/advertiser/revenue', customConfig)
    .then((response) => {
      console.log('user response campaign filter=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function loginV2(email, password) {
  localStorage.removeItem('user');
  const requestBody = {
    email: email,
    password: password,
  };
 // console.log("req=", requestBody);
  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + '/v1/v2/login', qs.stringify(requestBody), config)
    .then((response) => {
     // console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
     // console.log(error);
      return [];
    });
}

function verifyMFACode(user_id, mfa_auth_code) {
  const body = {
    auth_token: mfa_auth_code,
    id: user_id,
  };
  
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
    },
  };

  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + "/v1/verify/mfa", body, customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("MFA verify error", err);
      return { success: false };
    });
}

function getHTMLDeviceCount(params) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': true,
      crossorigin: true,
      'access-token': user.token,
    },
    params : params
  };

  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/report/htmlplayer/count', customConfig).then((response) => {
    return response.data;
  }).catch((error) => {
    console.log("HTML count error: ", error);
    return {success : false}
  })
}

function resendOTP(body) {  
  const customConfig = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": true,
      crossorigin: true,
    },
  };

  return axios
    .post(process.env.REACT_APP_API_SERVER_HOST + "/v1/resend/otp", body, customConfig)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("MFA verify error", err);
      return { success: false };
    });
}

// eslint-disable-next-line
export const service = {
  login,
  forgot,
  allCount,
  clientListAll,
  clientAdd,
  demandMap,
  imageUploadHandler,
  clientEdit,
  clientDelete,
  locationListAll,
  locationAll,
  clientAll,
  userNameAll,
  clientChange,
  locationListOrganizationAll,
  locationFilter,
  isEmailIdExist,
  locationType,
  playlistListAll,
  offlineVideos,
  playlistListOrganizationAll,
  locationAdd,
  clientUpdate,
  locationEdit,
  locationUpdate,
  usernameListClientAll,
  dataURLtoBlob,
  user,
  fetchPlaylistData,
  clientDataList,
  activeData,
  getSuper,
  advertiserData,
  verifyMFACode,
  loginV2,
  getHTMLDeviceCount,
  resendOTP,
};


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState,Fragment } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown, Button, Col, Row, Label,
  Modal, ModalHeader, ModalBody, ModalFooter,FormGroup, Input } from 'reactstrap';
import { service } from "../../network/UserManagement/service";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import team3 from '../../assets/img/team/avatar.png';
import Avatar from '../common/Avatar';
import { toast } from 'react-toastify';

const ProfileDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const user = JSON.parse(localStorage.getItem('user'));
  const userid = user.user_id; 
  const roleId = JSON.parse(localStorage.getItem('role_id'));
  const impersonate = JSON.parse(localStorage.getItem('userImpersonate'));
  const [modalOpen,setModalOpen] = useState(false);
  const [resetpasswordvalues,setResetPasswordValues]=useState({
    password:"",
    confirm_password:""
  });
  const [errors, setErrors] = useState({
    password:"",
    confirm_password:""
  });
  const [showPassword,setShowPassword]=useState(false);
  const [showConfirmPassword,setConfirmPassword]=useState(false);
  const [passwordEye, setPasswordEye] = useState(<FontAwesomeIcon icon={faEye} />);
  const [isPasswordEye, setIsPasswordEye] = useState(false);
  const [confirmPasswordEye, setConfirmPasswordEye] = useState(<FontAwesomeIcon icon={faEye} />);
  const [isConfirmPasswordEye, setIsConfirmPasswordEye] = useState(false);

  const revertToSuperAdmin = () => {
    localStorage.setItem('user', impersonate);
    localStorage.setItem('userid', impersonate.user_id)
    localStorage.setItem('role_id',26);

    localStorage.removeItem('client_id');
    localStorage.removeItem('userImpersonate');
    toast.success(`Logged in as Super Admin`);
    window.location.href = "/";
  }

  const handlePassword = (event) => {
    const { name, value } = event.target;
    setResetPasswordValues({
      ...resetpasswordvalues,
      [name]: value,
    });
    if (name == "password") {
      if (value.length > 0) {
        setIsPasswordEye(true);
      } else {
        setIsPasswordEye(false);
      }
    }
    if (name == "confirm_password") {
      if (value.length > 0) {
        setIsConfirmPasswordEye(true);
      } else {
        setIsConfirmPasswordEye(false);
      }
    }
  }

  const validatePassword = (password) => {
    if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/.test(password)) {
      return true;
    }
    return false;
  }

  const validate = () => {
    let errors = {};
    let formIsValid = true;
   
    if (!resetpasswordvalues.password.trim()) {
      formIsValid = false;
      errors.password = "Password is required!";
    } else {
      var errorMsg = validatePassword(resetpasswordvalues.password);
      if (errorMsg === true) {
        errors.password = "";
      } else {
        formIsValid = false;
        errors.password ="Invalid password!"
      }
    }
    if (!resetpasswordvalues.confirm_password.trim()) {
      formIsValid = false;
      errors.confirm_password = "Confirm Password is required!";
     } 
    
    if(resetpasswordvalues.password !=resetpasswordvalues.confirm_password){
      formIsValid = false;
      errors.confirm_password = "Please make sure both password match";      
    }
  
    setErrors(errors);
    return formIsValid;
  }

  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
    setPasswordEye(
      showPassword ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };

  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPassword(showConfirmPassword ? false : true);
    setConfirmPasswordEye(
      showConfirmPassword ? (
        <FontAwesomeIcon icon={faEye} />
      ) : (
        <FontAwesomeIcon icon={faEyeSlash} />
      )
    );
  };

  const updatePassword=()=>{
    if(validate()){
      service.resetPassword(userid,resetpasswordvalues.password).then(
       (response) => {
         toast.success("Password Reset successful");
         setModalOpen(!modalOpen);
       })
    }
    else{
      console.log("SOME ISSUES");
    }
  }

  const closeModal=()=>{
    setModalOpen(false);
    setResetPasswordValues({
      password:"",
      confirm_password:""
    });
    setErrors({
      password:"",
      confirm_password:""
    });
  }

  return (
    <>
      <Dropdown
        nav
        inNavbar
        isOpen={dropdownOpen}
        toggle={toggle}
        onMouseOver={() => {
          let windowWidth = window.innerWidth;
          windowWidth > 992 && setDropdownOpen(true);
        }}
        onMouseLeave={() => {
          let windowWidth = window.innerWidth;
          windowWidth > 992 && setDropdownOpen(false);
        }}
      >
        <DropdownToggle nav className="pr-0">
          <Avatar src={team3} />
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-card">
          <div className="bg-white rounded-soft py-2">
            <DropdownItem className="font-weight-bold text-warning" href="#!">
              {/* <FontAwesomeIcon icon="crown" className="mr-1" /> */}
              <span>{user.name}</span>
            </DropdownItem>
            {/* <DropdownItem divider />
            <DropdownItem href="#!">Set status</DropdownItem>
            <DropdownItem tag={Link} to="/pages/profile">
              Profile &amp; account
            </DropdownItem>
            <DropdownItem href="#!">Feedback</DropdownItem>
            <DropdownItem divider /> */}
            {(roleId ===26) && <DropdownItem  onClick={()=> setModalOpen(true)}>
              <span>Reset Password</span>
              </DropdownItem> }
            {(impersonate) ? <DropdownItem onClick={() => { revertToSuperAdmin() }} >
              Revert to Super Admin
            </DropdownItem> : <DropdownItem tag={Link} to="/authentication/basic/logout">
                Logout
            </DropdownItem>}


          </div>
        </DropdownMenu>
      </Dropdown>
      <Modal
    isOpen={modalOpen}
    toggle={closeModal}
    >
      <ModalHeader>Reset Password</ModalHeader>
      <ModalBody>
        <FormGroup className="form-check">
          <Row noGutters>
            {/* lg={7} className="pr-lg-4" */}
            <Col lg={12}>
              <Label for="exampleName">New password *</Label>
              <div style={{display:"flex",flexDirection:"row"}}>

            
            <Input
              type={showPassword ? "text" :"password"}
              name="password"
              id="password"
              placeholder="Reset"
              onChange={handlePassword}
            />
            {isPasswordEye && (
              <i 
              className="eyeIcon"
              style={{paddingTop:"0.5rem",paddingLeft:"0.4rem"}}
              onClick={togglePasswordVisiblity}
              >
              {passwordEye}
              </i>
              )}
              </div>
              {errors.password && errors.password!=="Invalid password!" &&(
              <p style={{ color: "red", fontStyle: "Italic" }}>
              {errors.password}    
              </p>
              )}

            {errors.password==="Invalid password!" && (
              
              <Fragment>
                
                <OverlayTrigger
                key="trigger"
                placement={"top"}
                style={{ color: "green", fontStyle: "Italic",backgroundColor:"green" }}
                overlay={
                  <Tooltip
                  //  id={`tooltip-top`}
                  id = {`button-tooltip-2`}
                  >
                  
                    <p style={{ color: "white", fontStyle: "Italic"}}>
                    "Enter a valid password...! with 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter"
                    </p>
                  </Tooltip>
                }>
                <p style={{ color: "red", fontStyle: "Italic" }}>
                {errors.password}    
                </p>
              </OverlayTrigger>
              
              </Fragment>
            )}
            
          </Col>
          <Col lg={12}>
            <Label for="exampleName">Confirm password *</Label>
            <div style={{display:"flex",flexDirection:"row"}}>
            <Input
              type={showConfirmPassword ? "text" :"password"}
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm "
              onChange={handlePassword}
            />
            {isConfirmPasswordEye && (
              <i 
              className="eyeIcon"
              style={{paddingTop:"0.5rem",paddingLeft:"0.4rem"}}
              onClick={toggleConfirmPasswordVisiblity}
              >
              {confirmPasswordEye}
              </i>
              )}
              </div>
            {errors.confirm_password && (
              <p style={{ color: "red", fontStyle: "Italic" }}>
                {errors.confirm_password}
              </p>
            )}
          </Col>
          </Row>
      </FormGroup>
    </ModalBody>
    <ModalFooter>
      <Button
        onClick={closeModal}
      >
        CANCEL
      </Button>
      <Button color="light" onClick={updatePassword}>
        RESET PASSWORD
      </Button>
    </ModalFooter>
  </Modal>
    </>
  );
};

export default ProfileDropdown;

const axios = require('axios');
const qs = require('querystring');

const config = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Access-Control-Allow-Origin': true,
    crossorigin: true,
  },
};





function clientListAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/client/list', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function userListAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/user/all', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}



function roleAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/user/allRoles', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}
function usernameAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/organization/all', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function usernameAll2() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/organization/all', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/location/list', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function deviceAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/device/list', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function clientAll() {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/client/all', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function clientChange(clientId) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token
    },
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/client/all', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function clientChangeUser(clientId) {
  const user = JSON.parse(localStorage.getItem('user'));
  // console.log("user=",user);
  const roleid=localStorage.getItem('role_id');
  const requestBody = {
  client_id:clientId
  };
  // console.log("req=",requestBody);
  const customConfig = {
  headers: {
  'Content-Type': 'application/x-www-form-urlencoded',
  'Access-Control-Allow-Origin': true,
  crossorigin: true,
  'access-token': user.token,
  },
  params: {
    client_id: clientId,
    role_id:roleid
  }
  };
  return axios
  .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/client/listById', customConfig)
  .then((response) => {
  // console.log('user response=', response);
  return response.data;
  })
  .catch((error) => {
  console.log(error);
  return [];
  });
  }

  

 

    function organisationChangeUser(clientId,orgId) {
      const user = JSON.parse(localStorage.getItem('user'));
      const customConfig = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'access-token': user.token,
        },
        params: {
          // client_id: clientId
          organization_id :orgId
        }
      };
      return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/location/list', customConfig)
        .then((response) => {
          // console.log(response,'response edit details');
          return response.data;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    }
      












function deviceFilter(location_id) {

  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
    params: {
              
              location_id: location_id
    }
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/device/list', customConfig)
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function deviceFilter2(location_id) {

  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
    params: {
              
              location_id: location_id
    }
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/device/list', customConfig)
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function deviceFilter3(clientid) {

  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
    params: {
              
              client_id: clientid
    }
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/device/list', customConfig)
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function deviceFilter4(orgid) {

  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
    params: {
              
      organization_id: orgid
    }
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/device/list', customConfig)
    .then((response) => {
      // console.log(response,'response edit details');
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}


function getSuper(from,to,loc,mon,client_id,org_id,loc_id,dev_id,checked,checked2,checked3) {
  var from=from;
  var to=to;

  var loca='';
  var month='';
  var clientid='';
  var orgid='';
  var locationid='';
  var deviceid='';
  var role='';
  // var client_check=1;
  var organization_check='';
  var location_check='';
  var device_check='';
  var client_check ='&client_check=1';
   var roleid=localStorage.getItem('role_id');

   if(roleid == 1 || roleid == 10){
     checked=true;
   }else if(roleid == 12){
     checked=true;
     checked2=true;
   }

   

   if(checked == true){
    organization_check='&organization_check=1';
   }

   if(checked2 == true){
    location_check='&location_check=1';
   }

   if(checked3 == true){
    device_check='&device_check=1';
   }

   if(roleid !=''){
     role='&role_id='+roleid;
   }

 if(loc !=''){
    loca='&location='+loc;
 }
 if(mon !=''){
    month='&monthly=1';
 }
 if(client_id!= ''){
    clientid='&client_id='+client_id+'';
 }
 
 if(org_id!= ''){
    orgid='&org_id='+org_id+'';
 }
 if(loc_id!= ''){
    locationid='&loc_id='+loc_id+'';
 }
 if(dev_id!= ''){
    deviceid='&dev_id='+dev_id+'';
 }

  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/report/all?from='+from+'&to='+to+''+month+clientid+orgid+locationid+deviceid+role+organization_check+location_check+device_check+client_check, customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function getZero(from,to,org,loc,dev,mon,client_id,org_id,loc_id,dev_id) {

  const user = JSON.parse(localStorage.getItem('user'));
   var organ='';
   var loca='';
   var devi='';
   var month='';
   var clientid='';
   var orgid='';
   var locationid='';
   var deviceid='';

  if(org!=''){
     organ='&organization_check=1';
  }
  if(loc !=''){
     loca='&location_check=1';
  }
  if(dev!=''){
     devi='&device_check=1';
  }
  if(mon !=""){
     month='&monthly=1';
  }
  if(client_id!= ''){
     clientid='&client_id='+client_id+'';
  }
  if(org_id!= ''){
     orgid='&org_id='+org_id+'';
  }
  if(loc_id!= ''){
     locationid='&loc_id='+loc_id+'';
  }
  if(dev_id!= ''){
     deviceid='&dev_id='+dev_id+'';
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token
     
     
    },
  };

  //console.log("Api url: ", process.env.REACT_APP_API_SERVER_HOST + '/v1/report/zerorevenue?from='+from+'&to='+to+''+organ+loca+devi+month+clientid+orgid+locationid+deviceid);
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/report/zerorevenue?from='+from+'&to='+to+''+organ+loca+devi+month+clientid+orgid+locationid+deviceid, customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function usernameFilter(client_id) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    params: {
      client_id: client_id
      
    }
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/organization/list', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function usernameFilter2(clientids,orgids) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    params: {
      client_id:clientids,
      organization_id: orgids
      
    }
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/organization/list', customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationFilter(clientids,orgids,locationids) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    params: {
      client_id: clientids,
      organization_id : orgids,
      location_id:locationids
      
    }
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/location/list', customConfig)
    .then((response) => {
      console.log('user response location filter=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationFilter2(orgid) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    params: {
      organization_id : orgid,      
    }
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/location/list', customConfig)
    .then((response) => {
      console.log('user response location filter=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function locationFilter3(clientid) {
  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'access-token': user.token,
    },
    params: {
      client_id : clientid,      
    }
    };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + '/v1/location/list', customConfig)
    .then((response) => {
      console.log('user response location filter=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}



function getSuperstart(from,to) {
  var from=from;
  var to=to;
  var role='';
  var roleid=localStorage.getItem('role_id');

  if(roleid !=''){
    role='&role_id='+roleid;
  }

  const user = JSON.parse(localStorage.getItem('user'));
  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/report/all?from='+from+'&to='+to+''+role, customConfig)
    .then((response) => {
      console.log('user response=', response);
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}

function getGraph(fromDate,toDate, clientDetailId) {
  
//   var url_string = window.location.href
// var url = new URL(url_string);
// var ci = url.searchParams.get("ci");

// console.log('sdfsd',ci);
  const user = JSON.parse(localStorage.getItem('user'));
  let requestParams = {};
  requestParams.from = fromDate;
  requestParams.to = toDate;
  
  requestParams.role_id = user.role;
  if (user.role != 26) {
    requestParams.client_id = user.client_id
  }
  if(clientDetailId) {
    requestParams.client_id = clientDetailId
  }
  if (user.role == 10) {
    requestParams.org_id = user.organization_id;
  }
  if (user.role == 12) {
    requestParams.loc_id = user.location_id;
  }

  const customConfig = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'access-token': user.token,
    },
    params: requestParams
  };
  return axios.get(process.env.REACT_APP_API_SERVER_HOST + '/v1/report/supergraph', customConfig)
 .then(response => {
      const graphData = response.data.data.map(item => ({
        cpm: item.revenue,
        request_timestamp: item.request_timestamp,
      }));
      return graphData;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });
}





function demandPartnerList() {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token
    }
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/partner/list",
      customConfig
    )
    .then(response => {
      return response.data;
    })
    .catch(error => {
      return [];
    });
}
function getSuperGraph(fromDate, toDate, client, sspid,org_id, loc_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  let requestParams = {};
  requestParams.from = fromDate;
  requestParams.to = toDate;

  requestParams.role_id = user.role;

  if (client != "") {
    requestParams.client_id = client;
  }
  if (sspid != "") {
    requestParams.SSPID = parseInt(sspid);
  }
  if (org_id != "") {
    requestParams.org_id = org_id;
  }
  if (loc_id != "") {
    requestParams.loc_id = loc_id;
  }
  console.log(user.token);
  console.log(".......req.body gragh", requestParams);
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token
    },
    params: requestParams
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/report/supergraph",
      customConfig
    )
    .then(response => {
      console.log("user response= grapgh", response);
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return [];
    });
}

function deviceFilterAll(clientid, orgid) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token
    },
    params: {
      client_id: clientid,
      organization_id: orgid
    }
  };
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/device/list",
      customConfig
    )
    .then(response => {
     
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return [];
    });
}

function getDisplayDemandSuperGraph(fromDate, toDate, client, sspid,org_id, loc_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  let requestParams = {};
  requestParams.from = fromDate;
  requestParams.to = toDate;

  requestParams.role_id = user.role;

  if (client != "") {
    requestParams.client_id = client;
  }
  if (sspid != "") {
    requestParams.SSPID = parseInt(sspid);
  }
  if (org_id != "") {
    requestParams.org_id = org_id;
  }
  if (loc_id != "") {
    requestParams.loc_id = loc_id;
  }
  console.log(user.token);
  console.log(".......req.body gragh", requestParams);
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token
    },
    params: requestParams
  };
  console.log("COOOOO");
  return axios
    .get(
      process.env.REACT_APP_API_SERVER_HOST + "/v1/report/displayDemandSupergraph",
      customConfig
    )
    .then(response => {
      console.log("user response= grapgh", response);
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return [];
    });
}

function getActiveLocations(client_id, organization_id, role_id) {
  const user = JSON.parse(localStorage.getItem("user"));
  const customConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "access-token": user.token
    },
    params: {
      role_id: role_id,
      client_id: client_id,
      organization_id: organization_id
    }
  };
  return axios
    .get(process.env.REACT_APP_API_SERVER_HOST + "/v1/getActiveLocation",
      customConfig
    )
    .then(response => {
     
      return response.data;
    })
    .catch(error => {
      console.log(error);
      return [];
    });
}
// eslint-disable-next-line
export const service = {
  clientListAll,
  userListAll,
  roleAll,
  getSuper,
  getZero,
  getGraph,
  getSuperstart,
  clientAll,
  deviceFilter,
  usernameAll,
  locationAll,
  clientChange,
  clientChangeUser,
  usernameFilter,
  usernameFilter2,
  locationFilter,
  locationFilter2,
  deviceFilter2,
  usernameAll2,
  deviceAll,
  locationFilter3,
  deviceFilter3,
  deviceFilter4,
  organisationChangeUser,
  getSuperGraph,
  demandPartnerList,
  deviceFilterAll,
  getDisplayDemandSuperGraph,
  getActiveLocations
  
};
